export const pages = [
  { title: "Startseite", icon: "store_mall_directory", path: "" },
  { title: "Speisekarte", icon: "restaurant", path: "/menu" },
  { title: "Anfahrt", icon: "map", path: "/#contact" },
];

export const getLocation = (city) =>
  /^(bochum|dortmund|siegen|gelsenkirchen|lüdenscheid)$/.test(city)
    ? city
    : "bochum";

export const locations = {
  bochum: {
    address: "Viktoria Straße 43 - 45",
    postcode: "44787 Bochum",
    tel: "0234 - 927 26 123",
    owner: "Jun Zhang",
    vatId: "DE304572836",
    vatNumber: "306/5265/2813",
    lunch: {
      openingHour: ["12:00", "15:00"],
      kitchen: "14:45",
      price: {
        "Mo. - Do.": "18.90 €",
        "Fr. - So. & Feiertage": "21.90 €",
      },
    },
    dinner: {
      openingHour: ["17:30", "23:00"],
      kitchen: "22:45",
      price: {
        "Mo. - Do.": "28.90 €",
        "Fr. - So. & Feiertage": "30.90 €",
      },
    },
    reservation: "https://www.quandoo.de/place/yumini-bochum-24009",
    parking: [
      {
        name: "P2 Dr.-Ruer-Platz",
        location: "Viktoriastraße 10, 44787 Bochum",
      },
      {
        name: "P8 Konrad-Adenauer-Platz",
        location: "Konard-Adenauer-Platz，44787 Bochum",
      },
    ],
    facebook: "https://www.facebook.com/yumini.bochum",
    googleMapLink: "https://goo.gl/maps/BwyAvCov1obF6MTQ8",
  },
  dortmund: {
    address: "Stubengasse 8",
    postcode: "44135 Dortmund",
    tel: "0231 - 533 065 88",
    owner: "Jiahua Shaohua Chen",
    vatId: "DE347894949",
    vatNumber: "314/5029/6671",
    lunch: {
      openingHour: ["12:00", "15:00"],
      kitchen: "14:45",
      price: {
        "Mo. - Do.": "19.90 €",
        "Fr. - So. & Feiertage": "21.90 €",
      },
    },
    dinner: {
      openingHour: ["17:30", "23:00"],
      kitchen: "22:45",
      price: {
        "Mo. - Do.": "31.90 €",
        "Fr. - So. & Feiertage": "32.90 €",
      },
    },
    reservation:
      "https://www.app.teburio.de/widget/newBooking?source=widgetPage&locid=EPen9478azg4WSz45&color=%2339a7df",
    parking: [
      {
        name: "Parkhaus Kuckelke",
        location: "Kuckelke 3, 44135 Dortmund",
      },
      {
        name: "Konzerthaus Tiefgarage",
        location: "Burgwall 8, 44135 Dortmund",
      },
    ],
    facebook: "https://www.facebook.com/yumini.dortmund",
    googleMapLink: "https://g.page/yumini-dortmund?share8",
  },
  gelsenkirchen: {
    address: "Willy-Brandt-Allee 54",
    postcode: "45891 Gelsenkirchen",
    tel: "0209 - 3807829",
    owner: "Xiao Ji",
    vatId: "DE264561769",
    vatNumber: "319/5658/5508",
    lunch: {
      openingHour: ["12:00", "15:00"],
      kitchen: "14:45",
      price: {
        "Mo.": "geschlossen",
        "Di. - Do.": "18.90 €",
        "Fr. - So. & Feiertage": "21.90 €",
      },
    },
    dinner: {
      openingHour: ["17:30", "22:00 (Fr. - So. & Feiertage bis 22:30)"],
      kitchen: "21:30 (Fr. - So. & Feiertage bis 22:00)",
      price: {
        "Mo.": "geschlossen",
        "Di. - Do.": "28.90 €",
        "Fr. - So. & Feiertage": "30.90 €",
      },
    },
    reservation: "https://www.quandoo.de/place/yumini-gelsenkirchen-27041",
    parking: [
      {
        name: "Parkplatz Veltins-Arena",
        location: "Berni-Klodt-Weg，45891 Gelsenkirchen",
      },
      {
        name: "Schalke Parkplatz",
        location: "Adenauerallee 110，45891 Gelsenkirchen",
      },
    ],
    facebook: "https://www.facebook.com/YUMINI-Gelsenkirchen-1005684199518313",
    googleMapLink: "https://g.page/yumini-gelsenkirchen?share",
  },
  lüdenscheid: {
    address: "Humboldtstraße 36 (Eigene Parkplätze)",
    postcode: "58511 Lüdenscheid",
    tel: "02351-8940039",
    owner: "Xueli Zhu",
    vatId: "DE368598281",
    vatNumber: "332/5201/4262",
    lunch: {
      openingHour: ["12:00", "15:00"],
      kitchen: "14:45",
      price: {
        "Mo. - Do.": "vormittag geschlossen außer Feiertage",
        "Fr. - So. & Feiertage": "19.90 €",
      },
    },
    dinner: {
      openingHour: ["17:30", "23:00"],
      kitchen: "22:45",
      price: {
        "Mo. - Do.": "29.90 €",
        "Fr. - So. & Feiertage": "31.90 €",
      },
    },
    reservation:
      "https://www.quandoo.de/de/checkout-widget/widget?merchantId=103653&primaryColor=df5157&theme=light&aid=146&utm_source=quandoo-partner&utm_medium=widget-link",
    parking: [
      {
        name: "Yumini Lüdenscheid",
        location:
          "Sie können in der Nähe des Restaurants kostenlos parken. Wir haben über dreißig Parkplätze, die Sie während Ihres Restaurantbesuchs nutzen können.",
      },
    ],
    facebook: "https://www.facebook.com/profile.php?id=61563771341998&sk=about",
    googleMapLink: "https://goo.gl/maps/ajrJF478tBnTkFB8A",
  },
  siegen: {
    address: "Koblenzer Straße 18",
    postcode: "57072 Siegen",
    tel: "0271 - 317 606 50",
    owner: "Junyi Hu",
    vatId: "DE357890106",
    vatNumber: "342/5132/3682",
    lunch: {
      openingHour: ["12:00", "15:00"],
      kitchen: "14:30",
      price: {
        "Mo. - Do.": "18.90 €",
        "Fr. - So. & Feiertage": "20.90 €",
      },
    },
    dinner: {
      openingHour: ["17:30", "23:30"],
      kitchen: "22:30 (Fr. - So. & Feiertage bis 23:00)",
      price: {
        "Mo. - Do.": "28.90 €",
        "Fr. - So. & Feiertage": "30.90 €",
      },
    },
    reservation:
      "https://www.quandoo.de/checkout-widget/widget?aid=146&utm_source=quandoo-partner&utm_medium=widget-link&merchantId=24379&locale=de_DE",
    parking: [
      {
        name: "APCOA Parkhaus Apollotheater",
        location: "Morleystraße,57072 Siegen",
      },
      {
        name: "CONTIPARK Tiefgarage Unteres Schloss",
        location: "Obergraben 30,57072 Siegen",
      },
    ],
    facebook: "https://www.facebook.com/yumini.siegen",
    googleMapLink: "https://goo.gl/maps/ajrJF478tBnTkFB8A",
  },
};

export const menuCategories = [
  { value: "menu", label: "Alle" },
  { value: "dinner", label: "Dinner Only" },
  { value: "dessert", label: "Dessert" },
  { value: "salad", label: "Salad | Soups | Noodles | Rice" },
  { value: "sauce", label: "Saucen" },
  { value: "side", label: "Side Orders" },
  { value: "sushi", label: "Sushi" },
  { value: "grill", label: "Warm Dishes" },
];

export const dataPrivacyNotice =
  "Wir nutzen Cookies auf unserer Website. Einige von ihnen sind essenziell, während andere uns helfen, diese Website und Ihre Erfahrung zu verbessern.";

export const dataPrivacy =
  "Wir erheben, verarbeiten und nutzen Ihre Daten nur im Rahmen der gesetzlichen Bestimmungen. Diese Datenschutzerklärung gilt ausschließlich für die Nutzung der von uns angebotenen Webseiten. Sie gilt nicht für die Webseiten anderer Dienstanbieter, auf die wir lediglich durch einen Link verweisen. Bei der Nutzung unserer Webseiten bleiben Sie anonym, solange Sie uns nicht von sich aus freiwillig personenbezogene Daten zur Verfügung stellen. Personenbezogene Daten werden nur dann erhoben, wenn dies für die Nutzung der auf der Webseite angebotenen Leistungen, insbesondere Formularangebote, erforderlich ist. Wir werden die von Ihnen zur Verfügung gestellten Daten streng vertraulich behandeln. Ohne Ihre ausdrückliche Einwilligung geben wir keine persönlichen Daten weiter, es sei denn, dass wir rechtlich dazu verpflichtet sind. Wir weisen jedoch darauf hin, dass es bei der Übermittlung von Daten im Internet immer dazu kommen kann, dass Dritte Ihre Daten zur Kenntnis nehmen oder verfälschen. Sofern Sie es von uns verlangen, gewähren wir Ihnen Einblick in die über Sie gespeicherten Daten, beziehungsweise löschen diese. Wenn Sie Daten berichtigen, löschen oder einsehen wollen, genügt hierfür ein Schreiben an die im Impressum angegebene Adresse. Im Rahmen der Benachrichtigung unserer Kunden über Produktneuheiten, etc. senden wir Ihnen E-Mails nur zu, sofern Sie uns Ihre E-Mail-Adresse zur Verfügung stellen.";
